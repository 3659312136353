// =======================================
//   List of variables for Preset color
// =======================================
$brand-color1 : linear-gradient(83.31deg, #1DE9B6 21.22%, #1DC4E9 88.54%);
$brand-color2 : linear-gradient(206.48deg, #A389D4 11.14%, #899ED4 104.6%);
$brand-color3 : linear-gradient(207.92deg, #0398F2 11.42%, #38B9E7 106.55%);
$brand-color4 : linear-gradient(162.57deg, #C5ECF9 11.95%, #C4B4E4 97.32%);

// Gray color
$white: #ffffff;
$gray-100: #F8F9FA;
$gray-200: #F3F5F7;
$gray-300: #DBE0E5;
$gray-400: #BEC8D0;
$gray-500: #8996A4;
$gray-600: #5B6B79;
$gray-700: #3E4853;
$gray-800: #1D2630;
$gray-900: #131920;
$black: #000000;



$blue: #1abc9c;
$indigo: #6610f2;
$purple: #9B59B6;
$pink: #e83e8c;
$red: #E74C3C;
$orange: #E67E22;
$yellow: #F1C40F;
$green: #2ECC71;
$teal: #008080;
$cyan: #3498DB;



// fusv-disablex
$blue-100: tint-color($blue, 80%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 80%);

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);
